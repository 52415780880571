var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import Search from '../../components/admin/users/searchUsers/SearchUsers';
import Header from '../../components/licenseController/Header';
import { useTranslate } from '../../components/translation/Translate';
var SearchUsers = function () {
    var t = useTranslate();
    return (_jsxs(React.Fragment, { children: [_jsx(Header, { title: t('nav.search_users') }), _jsx("main", __assign({ className: "px-9 py-4" }, { children: _jsx(Search, {}) }))] }));
};
export default SearchUsers;
