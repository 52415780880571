var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useEffect, useState } from 'react';
/**
 * Since api calls are done with useEffect in this project
 * I think this is bit cleaner way to wrap async functions
 * instead of using plain useEffects all over the place.
 *
 * @returns Tuple [value?, isLoading, error?, refetch]
 */
export default function usePromise(promise, deps) {
    var _a = __read(useState(undefined), 2), value = _a[0], setValue = _a[1];
    var _b = __read(useState(undefined), 2), error = _b[0], setError = _b[1];
    function handlePromise() {
        setValue(undefined);
        setError(undefined);
        promise()
            .then(function (value) { return setValue(value); })
            .catch(function (error) { return setError(error); });
    }
    useEffect(function () {
        handlePromise();
    }, deps);
    var loading = value === undefined && error === undefined;
    return [value, loading, error, handlePromise];
}
