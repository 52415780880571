var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { NavLink, useMatch, useResolvedPath } from 'react-router-dom';
import { useTranslate } from '../../translation/Translate';
var MainNav = function () {
    var t = useTranslate();
    var menuItems = [
        {
            title: t('home.info'),
            path: '/',
        },
        {
            title: t('material_library.materials'),
            path: '/materials',
        },
        {
            title: t('nav.questioinnaires'),
            path: '/query',
        },
        {
            title: t('licenses.licenses'),
            path: '/license',
        },
        {
            title: t('nav.periods'),
            path: '/periods',
        },
        {
            title: t('nav.create_users'),
            path: '/create-users',
        },
        {
            title: t('nav.search_users'),
            path: '/search-users',
        },
        {
            title: t('nav.invitations'),
            path: '/invitations'
        }
    ];
    var IsLinkActive = function (path) {
        var resolved = useResolvedPath(path);
        return useMatch({
            path: path === '/' ? resolved.pathname : path + '/',
        });
    };
    return (_jsx("ul", { children: menuItems.map(function (item, key) {
            var match = IsLinkActive(item.path);
            return (_jsx("li", __assign({ className: "p-0 my-2" }, { children: _jsx(NavLink, __assign({ to: item.path, className: match
                        ? 'flex w-11/12 px-2 ml-3 gap-2 py-2 rounded-md bg-white border-2 border-cyan'
                        : 'flex w-11/12 px-2 ml-3 gap-2 py-2 rounded-md bg-cyan border-2 border-cyan hover:bg-white duration-300' }, { children: item.title })) }), key));
        }) }));
};
export default MainNav;
