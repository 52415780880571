var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import { Modal } from '@mui/material';
import { Translate } from '../translation/Translate';
var ConfirmModal = function (_a) {
    var open = _a.open, onClose = _a.onClose, message = _a.message, deleteSelectedItem = _a.deleteSelectedItem, confirmButtonText = _a.confirmButtonText;
    return (_jsx(Modal, __assign({ open: open, onClose: onClose, "aria-labelledby": "modal-modal-title", "aria-describedby": "modal-modal-description", disableAutoFocus: true }, { children: _jsx("div", __assign({ className: "absolute z-30 h-52 bg-white rounded-md left-1/2 top-1/2", style: { width: '400px', transform: 'translate(-50%, -50%' } }, { children: _jsxs("div", __assign({ className: "flex flex-row" }, { children: [_jsx("div", { className: "bg-warning-400 rounded-l-md h-52", style: { width: '100px' } }), _jsxs("div", { children: [_jsxs("div", __assign({ className: "text-left mt-4 py-3 px-8" }, { children: [_jsx("p", __assign({ className: "mb-3 font-semibold text-lg text-grey-800" }, { children: message[0] })), _jsx("p", __assign({ className: "mb-3 grey-500" }, { children: message[1] }))] })), _jsx(ClearRoundedIcon, { onClick: onClose, style: {
                                    cursor: 'pointer',
                                    width: '20px',
                                    height: '20px',
                                    color: 'black',
                                    position: 'absolute',
                                    right: '8px',
                                    top: '8px',
                                } }), _jsxs("div", __assign({ className: "absolute right-2 bottom-2 space-x-3" }, { children: [_jsx("button", __assign({ onClick: deleteSelectedItem, className: "h-11" }, { children: confirmButtonText || Translate({ term: 'actions.delete' }) })), _jsx("button", __assign({ onClick: onClose, className: "border-2 border-blue bg-white text-black hover:bg-blue hover:text-white duration-300" }, { children: Translate({ term: 'actions.cancel' }) }))] }))] })] })) })) })));
};
export default ConfirmModal;
