var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useTranslate } from '../../../translation/Translate';
import { toast } from 'react-toastify';
import { apiDelete, apiPost } from '../../../../actions/apiActions';
import UserRemoval from './UserRemoval';
import useUser from '../../../../hooks/useUser';
var successToast = { position: 'top-center', type: 'success' };
var errorToast = { position: 'top-center', type: 'error' };
var CustomerUpdate = function (_a) {
    var userInfoId = _a.userInfoId, userName = _a.userName, userEmail = _a.userEmail, onClose = _a.onClose;
    var t = useTranslate();
    var user = useUser();
    var _b = __read(useState(false), 2), removeUser = _b[0], setRemoveUser = _b[1];
    var deleteUser = function () { return __awaiter(void 0, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, apiDelete('/api/admin/patient', user.session, { userInfoId: userInfoId })];
                case 1:
                    _b.sent();
                    toast(t('common.delete_success'), successToast);
                    onClose();
                    return [3 /*break*/, 3];
                case 2:
                    _a = _b.sent();
                    toast(t('common.delete_failed'), errorToast);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var sendPasswordResetLink = function () { return __awaiter(void 0, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, apiPost('/api/users/forget-password', { email: userEmail }, user.session)];
                case 1:
                    _b.sent();
                    toast(t('common.send_success'), successToast);
                    return [3 /*break*/, 3];
                case 2:
                    _a = _b.sent();
                    toast(t('common.send_failed'), errorToast);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    if (removeUser) {
        return (_jsx(UserRemoval, { userName: userName, onRemove: function () { return deleteUser(); }, onCancel: function () { return setRemoveUser(false); } }));
    }
    return (_jsxs("div", __assign({ className: "flex flex-row gap-1" }, { children: [_jsx("button", __assign({ onClick: function () { return sendPasswordResetLink(); } }, { children: t('admin.customer.send_password_reset_link') })), _jsx("button", __assign({ onClick: function () { return setRemoveUser(true); } }, { children: t('admin.customer.remove_user') }))] })));
};
export default CustomerUpdate;
