var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import TabButton from '../../components/admin/materials/TabButton';
import Admin from '../../components/admin/users/Admin';
import LicenceController from '../../components/admin/users/LicenceController';
import Therapists from '../../components/admin/users/Therapists';
import Header from '../../components/licenseController/Header';
import { useTranslate } from '../../components/translation/Translate';
var CreateUsers = function () {
    var t = useTranslate();
    var _a = __read(useState('therapist'), 2), selectedTab = _a[0], setSelectedTab = _a[1];
    var tabs = [
        {
            id: 'therapist',
            title: t('home.therapists'),
            content: _jsx(Therapists, {}),
        },
        {
            id: 'admin',
            title: t('profile.admin'),
            content: _jsx(Admin, {}),
        },
        {
            id: 'licence',
            title: t('home.licence_controller'),
            content: _jsx(LicenceController, {}),
        }
    ];
    var index = tabs.findIndex(function (tab) { return tab.id === selectedTab; });
    return (_jsxs(React.Fragment, { children: [_jsx(Header, { title: t("nav.create_users") }), _jsxs("main", __assign({ className: "px-9 py-4" }, { children: [_jsx("div", __assign({ className: "mt-3 flex" }, { children: tabs.map(function (tab, index) { return (_jsx(TabButton, { id: tab.id, title: tab.title, selectedTab: selectedTab, setSelectedTab: setSelectedTab }, index)); }) })), tabs[index].content] }))] }));
};
export default CreateUsers;
