var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Modal, Stack } from '@mui/material';
import { useTranslate } from '../../translation/Translate';
import { useState } from 'react';
export default function ReturnLicense(_a) {
    var email = _a.email, licenseKey = _a.licenseKey, onReturn = _a.onReturn, onCancel = _a.onCancel;
    var t = useTranslate();
    var _b = __read(useState(""), 2), check = _b[0], setCheck = _b[1];
    return (_jsx(Modal, __assign({ open: true }, { children: _jsx(Stack, __assign({ direction: "row", alignItems: "center", justifyContent: "center", paddingTop: 5 }, { children: _jsxs(Stack, __assign({ direction: "column", gap: 3, className: "bg-white rounded p-5 max-w-5xl" }, { children: [_jsxs("h3", __assign({ className: "text-blue font-semibold" }, { children: [t('invitation.revoke'), " ", licenseKey, " ", _jsxs("i", { children: ["(", email, ")"] })] })), _jsx("p", { children: t('admin.license.returning_question') }), _jsx("p", { children: t("admin.license.returning_confirmation") }), _jsx("input", { type: "text", value: check, onChange: function (e) { return setCheck(e.target.value); } }), _jsxs(Stack, __assign({ direction: "row", gap: 3 }, { children: [_jsx("button", __assign({ disabled: check !== licenseKey, onClick: onReturn }, { children: t("invitation.revoke") })), _jsx("button", __assign({ onClick: onCancel }, { children: t("actions.cancel") }))] }))] })) })) })));
}
