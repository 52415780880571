var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import PeriodMaterialMarkdown from '../../common/period/PeriodMaterialMarkdown';
import { Translate } from '../../translation/Translate';
function htmlToMd(html) {
    var e_1, _a;
    var doc = new DOMParser().parseFromString(html, 'text/html');
    var result = '';
    try {
        for (var _b = __values(doc.body.childNodes), _c = _b.next(); !_c.done; _c = _b.next()) {
            var node = _c.value;
            if (node.nodeType === Node.TEXT_NODE) {
                result += node.textContent;
            }
            else if (node.nodeType === Node.ELEMENT_NODE) {
                var element = node;
                switch (element.tagName.toUpperCase()) {
                    case 'H1':
                        result += "# ".concat(htmlToMd(element.innerHTML), "\n");
                        break;
                    case 'H2':
                        result += "## ".concat(htmlToMd(element.innerHTML), "\n");
                        break;
                    case 'H3':
                        result += "### ".concat(htmlToMd(element.innerHTML), "\n");
                        break;
                    case 'H4':
                        result += "#### ".concat(htmlToMd(element.innerHTML), "\n");
                        break;
                    case 'H5':
                        result += "##### ".concat(htmlToMd(element.innerHTML), "\n");
                        break;
                    case 'H6':
                        result += "###### ".concat(htmlToMd(element.innerHTML), "\n");
                        break;
                    case 'I':
                        result += "*".concat(htmlToMd(element.innerHTML), "*");
                        break;
                    case 'STRONG':
                    case 'B':
                        result += "**".concat(htmlToMd(element.innerHTML), "**");
                        break;
                    case 'P':
                        result += "".concat(htmlToMd(element.innerHTML), "\n");
                        break;
                    case 'UL':
                        result += "- ".concat(htmlToMd(element.innerHTML), "\n\n");
                        break;
                    case 'A':
                        result += "[".concat(htmlToMd(element.innerHTML), "](").concat(element.getAttribute('href'), ")");
                        break;
                    case 'IMG':
                        result += "![".concat(element.getAttribute('alt'), "](").concat(element.getAttribute('src'), ")");
                        break;
                    default:
                        result += element.textContent;
                        break;
                }
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return result;
}
var MaterialDescription = function (_a) {
    var material = _a.material;
    return (_jsxs("div", __assign({ className: "mt-4 flex flex-col gap-3" }, { children: [material.description && material.description !== '' && !material.html && (_jsxs("div", { children: [_jsx("h4", __assign({ className: "text-blue font-semibold" }, { children: Translate({ term: 'material_library.content' }) })), _jsx("div", __assign({ className: "period-material-content" }, { children: _jsx(PeriodMaterialMarkdown, { text: material.description }) }))] })), material.description && material.description !== '' && material.html && (_jsxs("div", { children: [_jsx("h4", __assign({ className: "text-blue font-semibold" }, { children: Translate({ term: 'material_library.content' }) })), _jsx("div", __assign({ className: "period-material-content" }, { children: _jsx(PeriodMaterialMarkdown, { text: htmlToMd(material.description) }) }))] })), material.therapistInstructions && material.therapistInstructions !== '' && (_jsxs("div", { children: [_jsx("h4", __assign({ className: "text-blue font-semibold" }, { children: Translate({ term: 'material_library.assignment' }) })), _jsx("div", __assign({ className: "period-material-content" }, { children: _jsx(PeriodMaterialMarkdown, { text: material.therapistInstructions }) }))] })), material.patientInstructions && material.patientInstructions !== '' && (_jsxs("div", { children: [_jsx("h4", __assign({ className: "text-blue font-semibold" }, { children: Translate({ term: 'material_library.client_instructions' }) })), _jsx("div", __assign({ className: "period-material-content" }, { children: _jsx(PeriodMaterialMarkdown, { text: material.patientInstructions }) }))] }))] })));
};
export default MaterialDescription;
