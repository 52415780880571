var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Translate, useTranslate } from '../../translation/Translate';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { createNewCustomer } from '../../../actions/userActions';
import { DOC_URL } from '../../../config/constants';
var Phase3 = function (_a) {
    var _b;
    var accountInfo = _a.accountInfo, next = _a.next;
    var defaultValues = {
        username: accountInfo.username,
        password: '',
        password_again: '',
        approve1: 0,
        approve2: 0,
    };
    var t = useTranslate();
    var _c = __read(useState(false), 2), valid = _c[0], setValid = _c[1];
    var _d = __read(useState(false), 2), validPassword = _d[0], setValidPassword = _d[1];
    var _e = __read(useState(false), 2), passwordsMatch = _e[0], setPasswordsMatch = _e[1];
    var _f = __read(useState('password'), 2), inputType = _f[0], setInputType = _f[1];
    var _g = __read(useState(false), 2), showNotification = _g[0], setShowNotification = _g[1];
    var _h = __read(useState(defaultValues), 2), user = _h[0], setUser = _h[1];
    var queryString = window.location.search;
    var urlParams = new URLSearchParams(queryString);
    var invitationId = urlParams.get('id');
    var fields = [
        {
            label: t('account.select_username'),
            key: 'username',
            type: 'text',
            default: (_b = accountInfo.username) === null || _b === void 0 ? void 0 : _b.toLowerCase(),
        },
        {
            label: t('account.select_password'),
            key: 'password',
            type: 'password',
        },
        {
            label: t("account.password_again"),
            key: 'password_again',
            type: 'password',
        },
        {
            label: t("account.approve_usage"),
            key: 'approve1',
            type: 'checkbox',
        },
        {
            label: t("account.approve_terms"),
            key: 'approve2',
            type: 'checkbox',
        },
    ];
    function submitInfo() {
        var _a, _b, _c, _d, _e;
        return __awaiter(this, void 0, void 0, function () {
            var newUser, newUserInfo, createNewCustomerRequest, response;
            return __generator(this, function (_f) {
                switch (_f.label) {
                    case 0:
                        if (!!accountInfo.account) return [3 /*break*/, 2];
                        newUser = {
                            username: user.username,
                            password: user.password,
                        };
                        newUserInfo = {};
                        if (accountInfo.parent) {
                            newUserInfo = {
                                firstName: accountInfo.parent.firstName,
                                lastName: accountInfo.parent.lastName,
                                email: accountInfo.email,
                                phone: accountInfo.phone,
                                customerInfo: {
                                    dependants: (_a = accountInfo.patient) === null || _a === void 0 ? void 0 : _a.patientId,
                                    agreementApproved: new Date(),
                                },
                            };
                        }
                        else {
                            newUserInfo = {
                                firstName: (_b = accountInfo.patient) === null || _b === void 0 ? void 0 : _b.firstName,
                                lastName: (_c = accountInfo.patient) === null || _c === void 0 ? void 0 : _c.lastName,
                                email: accountInfo.email,
                                phone: accountInfo.phone,
                                customerInfo: {
                                    patient: (_d = accountInfo.patient) === null || _d === void 0 ? void 0 : _d.patientId,
                                    agreementApproved: new Date(),
                                },
                            };
                        }
                        createNewCustomerRequest = {
                            user: newUser,
                            userInfo: newUserInfo,
                            invitationId: invitationId,
                            password: (_e = accountInfo === null || accountInfo === void 0 ? void 0 : accountInfo.patient) === null || _e === void 0 ? void 0 : _e.ssn,
                        };
                        return [4 /*yield*/, createNewCustomer(createNewCustomerRequest)];
                    case 1:
                        response = _f.sent();
                        if (response) {
                            next();
                        }
                        else {
                            setShowNotification(true);
                        }
                        _f.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    }
    function handleUserChange(s, f) {
        var _a, e_1, _b;
        var validation = true;
        var validPw = false;
        var match = false;
        var userInfo = __assign(__assign({}, user), (_a = {}, _a[f] = s, _a));
        try {
            for (var _c = __values(Object.entries(userInfo)), _d = _c.next(); !_d.done; _d = _c.next()) {
                var _e = __read(_d.value, 2), key = _e[0], value = _e[1];
                if (key === 'password') {
                    if (value.toString().length < 13 ||
                        !value.toString().match(/[a-z]/g) ||
                        !value.toString().match(/[A-Z]/g) ||
                        !value.toString().match(/[0-9]/g) ||
                        !value.toString().match(/[^a-zA-Z\d]/g)) {
                        validation = false;
                        validPw = false;
                    }
                    else {
                        validPw = true;
                    }
                }
                else if (key === 'password_again') {
                    if (value === userInfo.password) {
                        match = true;
                    }
                    else {
                        validation = false;
                        match = false;
                    }
                }
                if (key === 'approve1' && Number(value) < 1)
                    validation = false;
                if (key === 'approve2' && Number(value) < 1)
                    validation = false;
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_d && !_d.done && (_b = _c.return)) _b.call(_c);
            }
            finally { if (e_1) throw e_1.error; }
        }
        setUser(userInfo);
        setValid(validation);
        setValidPassword(validPw);
        setPasswordsMatch(match);
    }
    return (_jsxs(React.Fragment, { children: [_jsx("h2", __assign({ className: "text-xl" }, { children: Translate({ term: 'account.create_account' }) })), _jsx("div", __assign({ className: "text-success-600 px-4 mb-6 py-2 bg-white border border-success-600 rounded-md" }, { children: _jsx("p", { children: t("profile.password_change_info") }) })), _jsxs("div", __assign({ className: "grid gap-4" }, { children: [fields.map(function (f, key) {
                        if (f.type === 'password') {
                            var validation = validPassword;
                            var valueLength = user.password.length;
                            if (f.key === 'password_again') {
                                validation = passwordsMatch;
                                valueLength = user.password_again.length;
                            }
                            return (_jsxs("label", __assign({ className: "grid gap-y-2" }, { children: [_jsx("div", __assign({ className: "text-left" }, { children: f.label })), _jsxs("div", __assign({ className: "relative block" }, { children: [_jsx("input", { className: "w-full", type: inputType, name: f.key, onChange: function (e) { return handleUserChange(e.target.value, f.key); } }), valueLength > 0 ? (_jsx("span", __assign({ className: "absolute inset-y-0 right-0 flex items-center pr-2" }, { children: _jsx("div", __assign({ className: "h-5 w-5 -mt-1" }, { children: validation ? (_jsx(CheckCircleIcon, { className: "text-green" })) : (_jsx(ErrorIcon, { className: "text-orange" })) })) }))) : ('')] }))] }), key));
                        }
                        else if (f.type === 'checkbox') {
                            return (_jsxs("label", __assign({ className: "py-1 flex gap-2" }, { children: [_jsx("input", { type: "checkbox", value: 1, name: f.key, className: "mt-1", checked: f.key === 'approve2' && user.approve2
                                            ? true
                                            : f.key === 'approve1' && user.approve1
                                                ? true
                                                : false, onChange: function (e) {
                                            return handleUserChange(e.target.checked ? 1 : 0, f.key);
                                        } }), _jsx("span", __assign({ className: "text-left" }, { children: f.label }))] }), key));
                        }
                        else if (f.key === 'username') {
                            return (_jsxs("label", __assign({ className: "grid gap-y-2" }, { children: [_jsx("div", __assign({ className: "text-left" }, { children: f.label })), _jsx("input", { type: "text", value: user.username, onChange: function (e) {
                                            return handleUserChange(e.target.value.toLowerCase(), f.key);
                                        }, name: f.key })] }), key));
                        }
                        return (_jsxs("label", __assign({ className: "grid gap-y-2" }, { children: [_jsx("div", __assign({ className: "text-left" }, { children: f.label })), _jsx("input", { type: "text", defaultValue: f.default, onChange: function (e) { return handleUserChange(e.target.value, f.key); }, name: f.key })] }), key));
                    }), _jsx("div", __assign({ className: "flex justify-center space-x-5 mb-6" }, { children: _jsx("div", __assign({ onClick: function () {
                                inputType === 'password'
                                    ? setInputType('text')
                                    : setInputType('password');
                            }, className: "cursor-pointer" }, { children: inputType === 'password' ? (_jsx(VisibilityOffIcon, {})) : (_jsx(VisibilityIcon, {})) })) })), showNotification && _jsx("div", { children: t("account.unqualified_username") }), _jsxs("div", __assign({ className: "flex" }, { children: [_jsx("div", __assign({ className: "flex-auto text-left" }, { children: _jsx("a", __assign({ href: "".concat(DOC_URL, "/kayttoehdot.pdf"), target: "_blank", rel: "noreferrer" }, { children: _jsx("button", __assign({ className: 'text-blue border border-blue bg-white' }, { children: t("actions.read_terms") })) })) })), _jsx("div", __assign({ className: "" }, { children: _jsx("button", __assign({ disabled: !valid, className: !valid ? 'opacity-25 cursor-not-allowed' : '', onClick: function () { return submitInfo(); } }, { children: t("actions.create_account") })) }))] }))] }))] }));
};
export default Phase3;
