var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useSelector, useDispatch } from 'react-redux';
import { toast, ToastContainer, Zoom } from 'react-toastify';
import { Translate } from '../../translation/Translate';
import { clearFormState, setIsLoading, setValidationErrors, } from '../../../reducers/adminLicenseSlice';
import { createAdminZone } from '../../../actions/adminActions';
import { setApiError } from '../../../reducers/apiErrorSlice';
import ZoneForm from './ZoneForm';
import { useEffect } from 'react';
import useUser from '../../../hooks/useUser';
var CreateZone = function () {
    var dispatch = useDispatch();
    var user = useUser();
    var apiErrors = useSelector(function (state) { return state.apiError.errors; });
    var validationErrors = useSelector(function (state) { return state.adminLicense.validationErrors; });
    var isLoading = useSelector(function (state) { return state.adminLicense.isLoading; });
    var zoneFormData = useSelector(function (state) { return state.adminLicense.zoneFormData; });
    var firstName = zoneFormData.firstName, lastName = zoneFormData.lastName, email = zoneFormData.email, emailVerify = zoneFormData.emailVerify, municipality = zoneFormData.municipality;
    var creatingFailedTranslate = Translate({ term: 'common.creating_failed' });
    var newZoneCreatedTranslate = Translate({
        term: 'messages.new_zone_created',
    });
    var firstnameMissingTranslate = Translate({
        term: 'common.firstname_missing',
    });
    var lastnameMissingTranslate = Translate({
        term: 'common.lastname_missing',
    });
    var emailMissingTranslate = Translate({
        term: 'common.email_missing',
    });
    var emailsDontMatchTranslate = Translate({
        term: 'common.emails_dont_match',
    });
    var municipalityMissingTranslate = Translate({
        term: 'common.municipality_missing',
    });
    var formIsValid = function () {
        var errorMessages = [];
        if (!firstName)
            errorMessages.push(firstnameMissingTranslate);
        if (!lastName)
            errorMessages.push(lastnameMissingTranslate);
        if (!email)
            errorMessages.push(emailMissingTranslate);
        if (email !== emailVerify)
            errorMessages.push(emailsDontMatchTranslate);
        if (!municipality)
            errorMessages.push(municipalityMissingTranslate);
        return errorMessages;
    };
    var createNewZone = function () { return __awaiter(void 0, void 0, void 0, function () {
        var errorMessages;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    dispatch(setIsLoading(true));
                    dispatch(setValidationErrors([]));
                    errorMessages = formIsValid();
                    if (errorMessages.length > 0) {
                        dispatch(setValidationErrors(errorMessages));
                        dispatch(setIsLoading(false));
                        return [2 /*return*/];
                    }
                    if (!(validationErrors.length === 0 && user.session)) return [3 /*break*/, 2];
                    return [4 /*yield*/, createAdminZone(zoneFormData, user.session)
                            .then(function (newZone) {
                            if (newZone && newZone._id) {
                                showSuccessToastMessage(newZone.name);
                                dispatch(clearFormState());
                            }
                        })
                            .catch(function () { return showFailedToastMessage(); })];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2:
                    dispatch(setIsLoading(false));
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        // Reset possible api errors if page is reloaded. This is because store is persisted.
        dispatch(setApiError([]));
        dispatch(setValidationErrors([]));
    }, []);
    var showSuccessToastMessage = function (zoneName) {
        toast(newZoneCreatedTranslate + ' ' + (zoneName !== null && zoneName !== void 0 ? zoneName : ''), {
            position: 'top-center',
            hideProgressBar: true,
            transition: Zoom,
            closeButton: false,
            autoClose: 5000,
            toastId: 'successId',
            className: 'toast-message-success',
        });
    };
    var showFailedToastMessage = function () {
        toast(creatingFailedTranslate, {
            position: 'top-center',
            hideProgressBar: true,
            transition: Zoom,
            closeButton: false,
            autoClose: 5000,
            toastId: 'failedId',
            className: 'toast-message-warning',
        });
    };
    return (_jsxs(_Fragment, { children: [_jsx(ToastContainer, { limit: 1 }), _jsx(ZoneForm, { children: _jsxs(_Fragment, { children: [apiErrors &&
                            apiErrors.length > 0 &&
                            apiErrors.map(function (errorMessage, i) { return (_jsx("div", __assign({ className: "text-warning-600 px-4 py-2 bg-white border border-warning-600 rounded-md mt-3 mb-3" }, { children: Translate({ term: errorMessage }) }), i)); }), _jsxs("div", __assign({ className: "flex justify-end gap-4 mt-6" }, { children: [_jsx("button", __assign({ onClick: createNewZone, disabled: isLoading }, { children: Translate({ term: 'zone.create_zone' }) })), _jsx("button", __assign({ onClick: function () { return dispatch(clearFormState()); }, className: "border-2 border-blue bg-white text-black hover:bg-blue hover:text-white duration-300", disabled: isLoading }, { children: Translate({ term: 'actions.clear' }) }))] }))] }) })] }));
};
export default CreateZone;
