var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from '@mui/icons-material';
import { useTranslate } from '../../translation/Translate';
import { useState } from 'react';
import { Checkbox } from '@mui/material';
import clsx from 'clsx';
export default function LinkMaterial(_a) {
    var material = _a.material;
    var t = useTranslate();
    var _b = __read(useState(false), 2), showPreview = _b[0], setShowPreview = _b[1];
    function togglePreview() {
        setShowPreview(!showPreview);
    }
    return (_jsxs("div", __assign({ className: "flex flex-col gap-2" }, { children: [_jsxs("h2", __assign({ className: "text-blue" }, { children: [_jsx(Link, {}), " ", material.name] })), _jsxs("div", __assign({ className: "flex flex-col" }, { children: [_jsx("div", __assign({ className: clsx(showPreview &&
                            'border-l border-t border-r rounded-t border-gray-300 bg-lightBlue w-min pl-2') }, { children: _jsxs("div", __assign({ className: 'flex flex-row gap-2 items-center' }, { children: [t('material_library.show_preview'), _jsx(Checkbox, { value: showPreview, onClick: togglePreview })] })) })), showPreview ? (_jsx("div", __assign({ className: "border border-dashed rounded-r rounded-bl p-2 border-gray-300 bg-lightBlue" }, { children: _jsx("iframe", { className: "rounded", width: 520, height: 520, src: material.url, title: material.name }) }))) : (_jsx("div", __assign({ className: "bg-lightBlue rounded p-2" }, { children: _jsx("a", __assign({ href: material.url, className: 'underline' }, { children: material.url })) })))] }))] })));
}
