var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, } from 'react';
import { toast, ToastContainer, Zoom } from 'react-toastify';
import { apiDelete, apiGet, apiPost } from '../../actions/apiActions';
import PageSelect from '../../components/common/PageSelect';
import Header from '../../components/licenseController/Header';
import { useTranslate } from '../../components/translation/Translate';
import usePromise from '../../hooks/usePromise';
import useUser from '../../hooks/useUser';
import ReturnLicense from '../../components/admin/invitations/ReturnLicense';
var StyledTh = function (props) { return (_jsx("th", __assign({}, props, { className: "w-1/4 border-b px-4 py-3 text-left border-gray-400 cursor-pointer", style: { height: '60px' } }))); };
var StyledTd = function (props) { return (_jsx("td", __assign({}, props, { className: "px-4 py-4 text-left border-b border-gray-300 cursor-pointer" }))); };
var formatDate = function (date) {
    return new Date(date).toLocaleDateString('fi-FI');
};
var toastOptionsSuccess = {
    position: 'top-center',
    hideProgressBar: true,
    transition: Zoom,
    closeButton: false,
    autoClose: 5000,
    toastId: 'successId',
    className: 'toast-message-success',
};
var toastOptionsFailure = {
    position: 'top-center',
    hideProgressBar: true,
    transition: Zoom,
    closeButton: false,
    autoClose: 5000,
    toastId: 'failedId',
    className: 'toast-message-warning',
};
var Invitations = function () {
    var _a, _b;
    var t = useTranslate();
    var user = useUser();
    var _c = __read(useState(0), 2), page = _c[0], setPage = _c[1];
    var _d = __read(useState(null), 2), licenseToBeReturned = _d[0], setLicenseToBeReturned = _d[1];
    var _e = __read(usePromise(function () { return __awaiter(void 0, void 0, void 0, function () {
        var result;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, apiGet('/api/admin/invitations', user.session, {
                        page: page,
                    })];
                case 1:
                    result = _a.sent();
                    return [2 /*return*/, result.data];
            }
        });
    }); }, [page]), 4), invitations = _e[0], refetchInvitations = _e[3];
    function sendInvitationReminder(invitationId) {
        return __awaiter(this, void 0, void 0, function () {
            var result, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!invitationId) {
                            return [2 /*return*/];
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, apiPost('/api/admin/invitations/email', { invitationId: invitationId }, user.session)];
                    case 2:
                        result = _a.sent();
                        if (result.status === 200) {
                            toast('Lähetys onnistui', toastOptionsSuccess);
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _a.sent();
                        toast('Lähetys epäonnistui', toastOptionsFailure);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    }
    function returnLicenseAndRemoveInvitation(invitationId) {
        return __awaiter(this, void 0, void 0, function () {
            var result, error_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!invitationId) {
                            return [2 /*return*/];
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, apiDelete('/api/admin/invitations/return', user.session, { invitationId: invitationId })];
                    case 2:
                        result = _a.sent();
                        if (result.status === 200) {
                            toast('Lähetys onnistui', toastOptionsSuccess);
                        }
                        refetchInvitations();
                        return [3 /*break*/, 4];
                    case 3:
                        error_2 = _a.sent();
                        toast('Lähetys epäonnistui', toastOptionsFailure);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    }
    return (_jsxs(_Fragment, { children: [_jsx(ToastContainer, { limit: 1 }), _jsx(Header, { title: t('admin.invitations') }), _jsxs("main", __assign({ className: "px-9 py-4" }, { children: [_jsxs("table", __assign({ className: "table-fixed border-collapse rounded-md shadow-md w-full max-w-6xl overflow-hidden" }, { children: [_jsx("thead", { children: _jsxs("tr", __assign({ className: "bg-gray-100 text-xs px-4 py-2" }, { children: [_jsx(StyledTh, { children: t('profile.email_address') }), _jsx(StyledTh, { children: t('licenses.license_code') }), _jsx(StyledTh, { children: t('common.valid_until') }), _jsx(StyledTh, { children: t('invitation.resend') }), _jsx(StyledTh, { children: t('invitation.revoke') })] })) }), _jsxs("tbody", { children: [licenseToBeReturned && _jsx(ReturnLicense, __assign({}, licenseToBeReturned)), ((_a = invitations === null || invitations === void 0 ? void 0 : invitations.docs) !== null && _a !== void 0 ? _a : []).map(function (invitation) { return (_jsxs("tr", __assign({ className: "text-sm relative hover:bg-gray-100 cursor-pointer", style: { height: '60px' } }, { children: [_jsx(StyledTd, { children: invitation.email }), _jsx(StyledTd, { children: invitation.licenseKey }), _jsx(StyledTd, { children: formatDate(invitation.validUntil) }), _jsx(StyledTd, { children: _jsx("button", __assign({ onClick: function () { return sendInvitationReminder(invitation._id); } }, { children: "L\u00E4het\u00E4" })) }), _jsx(StyledTd, { children: _jsx("button", __assign({ onClick: function () {
                                                        var _a;
                                                        return setLicenseToBeReturned({
                                                            email: invitation.email,
                                                            licenseKey: (_a = invitation.licenseKey) !== null && _a !== void 0 ? _a : "",
                                                            onReturn: function () {
                                                                returnLicenseAndRemoveInvitation(invitation._id);
                                                                setLicenseToBeReturned(null);
                                                            },
                                                            onCancel: function () {
                                                                setLicenseToBeReturned(null);
                                                            }
                                                        });
                                                    } }, { children: "Palauta" })) })] }), invitation._id)); })] })] })), _jsx(PageSelect, { page: page, setPage: function (page) { return setPage(page); }, lastPage: (_b = invitations === null || invitations === void 0 ? void 0 : invitations.pages) !== null && _b !== void 0 ? _b : 1 })] }))] }));
};
export default Invitations;
