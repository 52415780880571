var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import moment from 'moment';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { useTranslate } from '../../../translation/Translate';
var TableDiv = function (_a) {
    var sort = _a.sort, reverse = _a.reverse, handleSetSort = _a.handleSetSort, users = _a.users, getSelectedUserFunction = _a.getSelectedUserFunction;
    var t = useTranslate();
    var headCells = [
        {
            id: 'lastName',
            label: t('profile.user'),
            content: function (user) {
                return (_jsxs("div", __assign({ className: "truncate .." }, { children: [user.firstName, " ", user.lastName] })));
            },
        },
        {
            id: 'email',
            label: t('profile.email'),
            content: function (user) {
                return _jsx("div", __assign({ className: "truncate .." }, { children: user.email }));
            },
        },
        {
            id: 'userType',
            label: 'Status',
            content: function (user) {
                return _jsx("div", __assign({ className: "truncate .." }, { children: user.status }));
            },
        },
        {
            id: 'createdAt',
            label: t('profile.user_created'),
            content: function (user) {
                return _jsx("div", { children: moment(user.createdAt).format('DD.MM.YYYY') });
            },
        },
    ];
    return (_jsxs("table", __assign({ className: "table-fixed border-collapse rounded-md shadow-md w-full max-w-6xl mt-8" }, { children: [_jsx("thead", { children: _jsx("tr", __assign({ className: "bg-gray-100 text-xs px-4 py-2", style: { height: '60px' } }, { children: headCells.map(function (headCell, key) {
                        // if (headCell.id !== 'status') {
                        var sortIcon = sort === headCell.id && reverse ? (_jsx(ArrowUpwardIcon, { className: sort === headCell.id
                                ? 'text-blue ml-1 opacity-100'
                                : 'text-blue ml-1 opacity-25', fontSize: "small" })) : (_jsx(ArrowDownwardIcon, { className: sort === headCell.id
                                ? 'text-blue ml-1 opacity-100'
                                : 'text-blue ml-1 opacity-25', fontSize: "small" }));
                        if (headCell.id === 'lastName') {
                            return (_jsxs("th", __assign({ onClick: function () { return handleSetSort(headCell.id); }, className: "w-1/4 border-b px-4 py-3 text-left border-gray-400 cursor-pointer" }, { children: [headCell.label, " ", sortIcon] }), key));
                        }
                        else if (headCell.id === 'createdAt') {
                            return (_jsxs("th", __assign({ onClick: function () { return handleSetSort(headCell.id); }, className: "w-1/4 border-b px-4 py-3 text-left border-gray-400 cursor-pointer" }, { children: [headCell.label, " ", sortIcon] }), key));
                        }
                        else if (headCell.id === 'email') {
                            return (_jsxs("th", __assign({ onClick: function () { return handleSetSort(headCell.id); }, className: "w-1/4 border-b px-4 py-3 text-left border-gray-400 cursor-pointer" }, { children: [headCell.label, " ", sortIcon] }), key));
                        }
                        return (_jsxs("th", __assign({ onClick: function () { return handleSetSort(headCell.id); }, className: "border-b px-4 py-3 text-left border-gray-400 cursor-pointer" }, { children: [headCell.label, " ", sortIcon] }), key));
                    }) })) }), _jsx("tbody", { children: users.map(function (user, index) {
                    return (_jsx("tr", __assign({ style: { height: '60px' }, className: "text-sm relative hover:bg-gray-100 cursor-pointer", onClick: function () { return getSelectedUserFunction(user._id); } }, { children: headCells.map(function (headCell, key) {
                            var content = headCell.content ? headCell.content(user) : '';
                            return (_jsx("td", __assign({ className: "px-4 py-4 text-left border-b border-gray-300 cursor-pointer" }, { children: content }), key));
                        }) }), (user === null || user === void 0 ? void 0 : user.firstName) + (user === null || user === void 0 ? void 0 : user.lastName) + (user === null || user === void 0 ? void 0 : user.createdAt)));
                }) })] })));
};
export default TableDiv;
