var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { toast, ToastContainer, Zoom } from 'react-toastify';
import { getUser, getUsers } from '../../../../actions/userActions';
import PageSelect from '../../../common/PageSelect';
import UsersModal from '../../modals/users/UsersModal';
import TableDiv from './TableDiv';
import useUser from '../../../../hooks/useUser';
import UserType from '../../../../types/UserType';
import useComposite from '../../../../hooks/useComposite';
import usePromise from '../../../../hooks/usePromise';
var SearchUsers = function () {
    var _a;
    var user = useUser();
    var _b = __read(useState(null), 2), selected = _b[0], setSelected = _b[1];
    var _c = __read(useComposite({
        name: '',
        types: [UserType.Customer],
        reverse: false,
        sort: 'createdAt',
        page: 1,
    }), 2), search = _c[0], updateSearch = _c[1];
    var searchUsers = function () { return __awaiter(void 0, void 0, void 0, function () {
        var filter, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    filter = {
                        searchName: search.name,
                        types: search.types,
                        reverse: search.reverse ? -1 : 1,
                        sort: search.sort,
                        page: search.page,
                        limit: 8,
                    };
                    return [4 /*yield*/, getUsers(filter, user.session)];
                case 1:
                    data = _a.sent();
                    if (!data) {
                        return [2 /*return*/, {
                                users: [],
                                pages: 0,
                            }];
                    }
                    return [2 /*return*/, {
                            users: data.docs.map(function (entry) {
                                var _a;
                                return ({
                                    _id: entry._id,
                                    status: entry.userType.name,
                                    username: entry.userId.username,
                                    firstName: entry.firstName,
                                    lastName: entry.lastName,
                                    email: entry.email,
                                    phone: entry.phone,
                                    zone: [],
                                    evaluationZones: [],
                                    modules: [],
                                    createdAt: (_a = entry.createdAt) !== null && _a !== void 0 ? _a : new Date(),
                                });
                            }),
                            pages: data.totalPages,
                        }];
            }
        });
    }); };
    var _d = __read(usePromise(function () { return searchUsers(); }, [search]), 3), users = _d[0], loading = _d[1], error = _d[2];
    function handleSetSort(id) {
        if (search.sort && id === search.sort) {
            updateSearch({ reverse: !search.reverse });
        }
        else {
            updateSearch({
                sort: id,
                reverse: !search.reverse,
            });
        }
    }
    var getSelectedUserFunction = function (userInfoId) { return __awaiter(void 0, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getUser(userInfoId, user.session)];
                case 1:
                    data = _a.sent();
                    if (!data) {
                        return [2 /*return*/];
                    }
                    setSelected(data);
                    return [2 /*return*/];
            }
        });
    }); };
    var showToastMessage = function (info) {
        toast(info, {
            position: 'top-center',
            hideProgressBar: true,
            transition: Zoom,
            closeButton: false,
            autoClose: 2000,
            toastId: 'successId',
            className: 'toast-message-success',
        });
    };
    var toggleUserType = function (type) {
        if (search.types.includes(type)) {
            updateSearch({ types: search.types.filter(function (toggled) { return toggled !== type; }) });
        }
        else {
            updateSearch({ types: __spreadArray(__spreadArray([], __read(search.types), false), [type], false) });
        }
    };
    var refreshSearch = function () { return updateSearch({}); };
    return (_jsxs("div", __assign({ className: "mt-6", style: { minWidth: '700px' } }, { children: [_jsx(ToastContainer, { limit: 1 }), _jsxs("div", __assign({ className: "p-2 flex flex-row gap-2 justify-between" }, { children: [_jsx("div", { children: _jsxs("div", __assign({ className: "flex flex-row gap-2 items-center" }, { children: [_jsx("h5", __assign({ className: "font-bold" }, { children: "Nimi" })), _jsx("input", { type: "text", value: search.name, onChange: function (e) { return updateSearch({ name: e.target.value }); }, placeholder: "nimi" })] })) }), _jsxs("div", __assign({ className: "flex flex-col gap-1 w-96" }, { children: [_jsx("h5", __assign({ className: "font-bold" }, { children: "K\u00E4ytt\u00E4j\u00E4tyypit" })), _jsxs("div", __assign({ className: "grid grid-cols-2 gap-2" }, { children: [Object.values(UserType).map(function (type) {
                                        var _a;
                                        return (_jsxs("div", __assign({ className: "flex flex-row gap-2 items-center" }, { children: [_jsx("input", { type: "checkbox", checked: search.types.includes(type), onClick: function () { return toggleUserType(type); } }), _jsx("label", { children: (_a = {},
                                                        _a[UserType.Admin] = 'Admin',
                                                        _a[UserType.Customer] = 'Asiakas',
                                                        _a[UserType.LicenseController] = 'Lisenssikontrolleri',
                                                        _a[UserType.Therapist] = 'Terapisti',
                                                        _a)[type] })] }), type));
                                    }), _jsxs("div", __assign({ className: "flex flex-row gap-2 items-center" }, { children: [_jsx("input", { type: "checkbox", disabled: search.types.length === 0, checked: search.types.length === 0, onClick: function () { return updateSearch({ types: [] }); } }), _jsx("label", { children: "Kaikki" })] }))] }))] }))] })), loading && _jsx("span", { children: "Ladataan..." }), error && (_jsxs("span", __assign({ className: "text-warning-800" }, { children: ["Virhe: ", error.message] }))), selected && (_jsx(UsersModal, { showToastMessage: showToastMessage, selectedUser: selected, setSelectedUser: setSelected, searchAllUsers: refreshSearch })), users && (_jsx(TableDiv, { sort: search.sort, reverse: search.reverse, users: users.users, handleSetSort: handleSetSort, getSelectedUserFunction: getSelectedUserFunction })), _jsx(PageSelect, { page: search.page, setPage: function (page) { return updateSearch({ page: page }); }, lastPage: (_a = users === null || users === void 0 ? void 0 : users.pages) !== null && _a !== void 0 ? _a : 0 })] })));
};
export default SearchUsers;
