var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useTranslate } from '../../../translation/Translate';
var UserRemoval = function (_a) {
    var userName = _a.userName, onRemove = _a.onRemove, onCancel = _a.onCancel;
    var t = useTranslate();
    var _b = __read(useState(''), 2), confirmation = _b[0], setConfirmation = _b[1];
    return (_jsxs("div", __assign({ className: "flex flex-col gap-5" }, { children: [_jsx("span", { children: t('admin.customer.removal_question') }), _jsx("span", { children: t('admin.customer.removal_confirmation') }), _jsx("input", { type: "text", value: confirmation, onChange: function (e) { return setConfirmation(e.target.value); } }), _jsxs("div", __assign({ className: "flex flex-row gap-1" }, { children: [_jsx("button", __assign({ disabled: confirmation !== userName, onClick: onRemove }, { children: t('actions.delete') })), _jsx("button", __assign({ onClick: onCancel }, { children: t('actions.cancel') }))] }))] })));
};
export default UserRemoval;
